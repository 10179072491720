<template>
    <div id="guestAccessList" class="baseTemplate">
        <!-- begin:: mobile menu -->
        <app-mobilemenu></app-mobilemenu>
        <!-- end:: mobile menu -->

        <div class="kt-grid kt-grid--hor kt-grid--root">
            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                <!-- begin:: home menu -->
                <app-siteconfigurationmenu activeItem="guests"></app-siteconfigurationmenu>
                <!-- end:: home menu -->

                <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">
                    <!-- begin:: header -->
                    <app-header></app-header>
                    <!-- end:: header -->

                    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                        <!-- begin:: Content Head -->
                        <div class="kt-subheader kt-grid__item">
                            <app-sitetitle subHeaderDescKey="siteusers_guestAccess"></app-sitetitle>
                            <div class="kt-subheader__toolbar">
                                <div class="kt-subheader__wrapper">
                                    <router-link :to="{ name: 'newSiteGuest' }" id="guestAccess_createNewGuest" class="btn btn-brand btn-bold btn-sm btn-icon-h kt-margin-l-10">
                                        <i class="flaticon2-plus kt-margin-r-5"></i>
                                        {{ $t("siteusers_inviteNewGuest") }}
                                    </router-link>
                                </div>
                            </div>
                        </div>
                        <!-- end:: Content Head -->

                        <!-- begin:: Content -->
                        <div class="kt-content kt-grid__item kt-grid__item--fluid">
                            <div class="row">
                                <div class="col-xl-12">
                                    <div class="kt-portlet kt-portlet--height-fluid kt-portlet--mobile ">
                                        <div class="kt-portlet__head kt-portlet__head--lg kt-portlet__head--noborder kt-portlet__head--break-sm">
                                            <div class="kt-portlet__head-label">
                                                <span class="kt-portlet__head-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                            <polygon id="Shape" points="0 0 24 0 24 24 0 24" />
                                                            <path d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z" id="Mask" fill="#000000" fill-rule="nonzero" opacity="0.3" />
                                                            <path d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z" id="Mask-Copy" fill="#000000" fill-rule="nonzero" />
                                                        </g>
                                                    </svg>
                                                </span>
                                                <h3 class="kt-portlet__head-title">
                                                    {{ $t("siteusers_activeGuests") }}
                                                </h3>
                                            </div>
                                            <div v-if="!isEmptyList" class="kt-portlet__head-toolbar">
                                                <div class="kt-portlet__head-wrapper">
                                                    <div class="kt-portlet__head-toolbar">
                                                        <div class="row align-items-center">
                                                            <div class="col-md-12 kt-margin-b-20-tablet-and-mobile">
                                                                <div class="kt-input-icon kt-input-icon--left">
                                                                    <input type="text" :placeholder="$t('common_searchByNameOrEmail')" id="guests_searchInput" @keyup="onSearch" v-model="searchedValue" class="searchInputDatatable form-control form-control-sm width270" />
                                                                    <span class="kt-input-icon__icon kt-input-icon__icon--left">
                                                                        <span><i class="la la-search"></i></span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!--begin: emptyList component-->
                                        <app-emptylist targetObjectName="GUEST" mainTextKey="siteusers_guestNotFound" subTextKey="siteusers_guestNotFoundSubLabel" imgName="empty_email_invitation.svg" v-bind:isDisplayed="isEmptyList"></app-emptylist>
                                        <!--enf: emptyList component-->
                                        <div class="kt-portlet__body kt-portlet__body--fit">
                                            <!--begin: Datatable -->
                                            <div class="kt-datatable" id="guestAccess_guestsDatatable"></div>
                                            <!--end: Datatable -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xl-12">
                                    <div class="kt-portlet kt-portlet--height-fluid kt-portlet--mobile ">
                                        <div class="kt-portlet__head kt-portlet__head--lg kt-portlet__head--noborder kt-portlet__head--break-sm">
                                            <div class="kt-portlet__head-label">
                                                <span class="kt-portlet__head-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                            <rect x="0" y="0" width="24" height="24" />
                                                            <path d="M4,16 L5,16 C5.55228475,16 6,16.4477153 6,17 C6,17.5522847 5.55228475,18 5,18 L4,18 C3.44771525,18 3,17.5522847 3,17 C3,16.4477153 3.44771525,16 4,16 Z M1,11 L5,11 C5.55228475,11 6,11.4477153 6,12 C6,12.5522847 5.55228475,13 5,13 L1,13 C0.44771525,13 6.76353751e-17,12.5522847 0,12 C-6.76353751e-17,11.4477153 0.44771525,11 1,11 Z M3,6 L5,6 C5.55228475,6 6,6.44771525 6,7 C6,7.55228475 5.55228475,8 5,8 L3,8 C2.44771525,8 2,7.55228475 2,7 C2,6.44771525 2.44771525,6 3,6 Z" fill="#000000" opacity="0.3" />
                                                            <path d="M10,6 L22,6 C23.1045695,6 24,6.8954305 24,8 L24,16 C24,17.1045695 23.1045695,18 22,18 L10,18 C8.8954305,18 8,17.1045695 8,16 L8,8 C8,6.8954305 8.8954305,6 10,6 Z M21.0849395,8.0718316 L16,10.7185839 L10.9150605,8.0718316 C10.6132433,7.91473331 10.2368262,8.02389331 10.0743092,8.31564728 C9.91179228,8.60740125 10.0247174,8.9712679 10.3265346,9.12836619 L15.705737,11.9282847 C15.8894428,12.0239051 16.1105572,12.0239051 16.294263,11.9282847 L21.6734654,9.12836619 C21.9752826,8.9712679 22.0882077,8.60740125 21.9256908,8.31564728 C21.7631738,8.02389331 21.3867567,7.91473331 21.0849395,8.0718316 Z" fill="#000000" />
                                                        </g>
                                                    </svg>
                                                </span>
                                                <h3 class="kt-portlet__head-title">
                                                    {{ $t("siteusers_guestPending") }}
                                                </h3>
                                            </div>
                                            <div v-if="!isPendingGuestsEmptyList" class="kt-portlet__head-toolbar">
                                                <div class="kt-portlet__head-wrapper">
                                                    <div class="kt-portlet__head-toolbar">
                                                        <div class="row align-items-center">
                                                            <div class="col-md-12 kt-margin-b-20-tablet-and-mobile">
                                                                <div class="kt-input-icon kt-input-icon--left">
                                                                    <input type="text" :placeholder="$t('common_searchByNameOrEmail')" id="invitations_searchInput" @keyup="onSearchPendingGuests" v-model="searchedPendingGuestsValue" class="searchInputDatatable form-control form-control-sm width270" />
                                                                    <span class="kt-input-icon__icon kt-input-icon__icon--left">
                                                                        <span><i class="la la-search"></i></span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!--begin emptyList component-->
                                        <app-emptylist targetObjectName="PENDING_GUEST" mainTextKey="siteusers_pendingInvitationsNotFound" subTextKey="siteusers_pendingInvitationsNotFoundSubLabel" imgName="empty_email_invitation.svg" v-bind:isDisplayed="isPendingGuestsEmptyList"></app-emptylist>
                                        <!--end emptyList component-->
                                        <div class="kt-portlet__body kt-portlet__body--fit">
                                            <!--begin: Datatable -->
                                            <div class="kt-datatable" id="users_pendingGuestsDatatable"></div>
                                            <!--end: Datatable -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- end:: Content -->
                    </div>

                    <!-- begin:: Footer -->
                    <app-footer></app-footer>
                    <!-- end:: Footer -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import i18n from "../../i18n";
import moment from "moment";
import EmptyList from "./../utils/emptylist.vue";
import SiteTitle from "../site/widgets/sitetitle.vue";
import commonVueHelper from "../../helpers/commonVueHelper";

export default {
    data() {
        return {
            siteId: this.$route.params.siteId,
            siteGuestsTable: null,
            siteGuestsData: null,
            isSiteUserAdmin: false,
            isEmptyList: true,
            searchedValue: "",
            isPendingGuestsEmptyList: false,
            pendingGuestsTable: null,
            pendingGuestsData: null,
            searchedPendingGuestsValue: ""
        };
    },
    created: function() {
        console.log("Component(Guests)::created() - called");
        if (this.createAnotherGuest) {
            this.$router.push({ name: "newSiteGuest" });
        } else {
            // Load site guests
            this.getGuestsOfSite(this.siteId);
            this.getPendingGuestsOfSite(this.siteId);
        }
    },
    mounted: function() {
        console.log("Component(Guests)::mounted() - called");
        KTLayout.init();
    },
    beforeDestroy: function() {
        console.log("Component(Guests)::beforeDestroy() - called");
        // Reset component data
        if (this.siteGuestsTable) {
            this.siteGuestsTable.destroy();
            this.siteGuestsTable = null;
        }
        if(this.pendingGuestsTable){
            this.pendingGuestsTable.destroy();
            this.pendingGuestsTable = null;
        }
    },
    beforeRouteLeave(to, from, next) {
        this.resetSiteGuestsState();
        this.resetPendingGuestsState();
        next();
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        guestsOfSite: function(guests) {
            console.log("Component(Guests)::watch(guestsOfSite) - called with : ", guests);
            // Get the user role on the site
            this.getSiteUserById({ siteId: this.siteId, userId: this.user.id });
        },

        pendingGuestsOfSite: function(){
            console.log("Component(Guests)::watch(pendingGuestsOfSite) - called", this.pendingGuestsData);
            this.pendingGuestsData = this.buildPendingGuestsDatatableData();
            this.initPendingGuestsKtDataTable(this.pendingGuestsData);
        },

        user: function(user) {
            console.log("Component(Guests)::watch(user) - called with : ", user);
            if (user) {
                // In case of reload user (as for a language change)
                this.siteGuestsData = this.buildDatatableData();
                this.initKtDataTable(this.siteGuestsData);
                this.pendingGuestsData = this.buildPendingGuestsDatatableData();
                this.initPendingGuestsKtDataTable(this.pendingGuestsData);
               
            }
        },

        currentSiteUser(siteUser) {
            const siteUserRole = (siteUser && siteUser.role ? siteUser.role.name : '');
            console.log("Component(Guests)::watch(currentSiteUser) - called with : ", siteUser, " - site user role = " + siteUserRole);
            if (siteUserRole === 'ADMIN') {
                this.isSiteUserAdmin = true;
            }
            this.siteGuestsData = this.buildDatatableData();
            this.initKtDataTable(this.siteGuestsData);
            this.pendingGuestsData = this.buildPendingGuestsDatatableData();
            this.initPendingGuestsKtDataTable(this.pendingGuestsData);
        },

    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["guestsOfSite", "pendingGuestsOfSite", "user", "currentSiteUser", "createAnotherGuest"])
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions(["getGuestsOfSite", "getPendingGuestsOfSite", "resetSiteGuestsState", "showDeleteModal", "getSiteUserById", "reInviteToGuest", "resetPendingGuestsState"]),

        onEditSiteGuest: function(guestId) {
            // User clicks on Edit site user button in table
            this.$router.push({ name: "editSiteGuest", params: { guestId: guestId } });
        },

        onSearch() {
            if (this.siteGuestsTable) {
                const searchedValueUpperCase = this.searchedValue.toUpperCase();
                // Search in "fullName" guests data
                const dataFoundByName = _.filter(this.siteGuestsData, guest => guest && guest.fullName && guest.fullName.toUpperCase().includes(searchedValueUpperCase));
                // Search in "email" guests data
                const dataFoundByEmail = _.filter(this.siteGuestsData, guest => guest && guest.email && guest.email.toUpperCase().includes(searchedValueUpperCase));
                // Create an array with previous unique resulting searched
                const dataFound = _.unionBy(dataFoundByName, dataFoundByEmail, 'id');
                if (dataFound && dataFound.length > 0) {
                    // Reload datatable with only rows which contain searchedValue
                    this.initKtDataTable(dataFound, this.siteGuestsTable.getPageSize());
                } else {
                    // Display empty datatable
                    this.siteGuestsTable.search(this.searchedValue, "unknown");
                }
            }
        },

        // Execute when user write something in invitation search input
        onSearchPendingGuests() {
            if (this.pendingGuestsTable) {
                const searchedValueUpperCase = this.searchedPendingGuestsValue.toUpperCase();
                // Search in "name" invitations data
                const dataFoundByName = _.filter(this.pendingGuestsData, guest => guest && guest.name && guest.name.toUpperCase().includes(searchedValueUpperCase));
                // Search in "email" invitations data
                const dataFoundByEmail = _.filter(this.pendingGuestsData, guest => guest && guest.email && guest.email.toUpperCase().includes(searchedValueUpperCase));
                // Create an array with previous unique resulting searched
                const dataFound = _.unionBy(dataFoundByName, dataFoundByEmail, 'id');
                if (dataFound && dataFound.length > 0) {
                    // Reload datatable with only rows which contain searchedUserValue
                    this.initPendingGuestsKtDataTable(dataFound, this.pendingGuestsTable.getPageSize());
                } else {
                    // Display empty datatable
                    this.pendingGuestsTable.search(this.searchedPendingGuestsValue, "unknown");
                }
            }
        },

        registerEventsOnSiteGuestsTable() {
            var self = this;
            $("#guestAccess_guestsDatatable").on("kt-datatable--on-layout-updated", function() {
                $(".revokeGuestButton")
                    .off()
                    .on("click", function() {
                        let guestId = $(this).attr("data-guestid");
                        let userName = $(this).attr("data-username");
                        console.log("Component(Guests)::registerEventsOnSiteGuestsTable(revokeGuestButton) clicked. UserId = " + guestId + " and userName = " + userName);
                        self.showDeleteModal({ textContent: i18n.t("siteusers_revokeUserAccessConfirmText", { userName: userName }), actionName: "REVOKE_GUEST_ACCESS_TO_SITE", params: { guestId: guestId, siteId: self.siteId } });
                    });

                $(".editGuestButton")
                    .off()
                    .on("click", function() {
                        let guestId = $(this).attr("data-guestid");
                        console.log("Component(Guests)::registerEventsOnSiteGuestsTable(editGuestButton) clicked. UserId = " + guestId);
                        // Hide and destroy element’s tooltip
                        $('[data-toggle="kt-tooltip"]').tooltip("dispose");
                        // Go to edit vue
                        self.onEditSiteGuest(guestId);
                    });

                // Force width pager size
                setTimeout(() => {
                    $(".kt-datatable__pager-size").css("width", "80px");

                    // Init tooltips
                    $('[data-toggle="kt-tooltip"]').tooltip({ trigger: "hover" });
                }, 50);
            });
        },

        registerEventsOnPendingGuestsTable() {
            var self = this;
            $("#users_pendingGuestsDatatable").on("kt-datatable--on-layout-updated", function() {
                $(".deletePendingInvitationButton")
                    .off()
                    .on("click", function() {
                        let invitationId = $(this).attr("data-invitationid");
                        console.log("Component(Users)::registerEventsOnPendingInvitationsTable(revokeUserButton) clicked. Invitationid = " + invitationId);
                        self.showDeleteModal({ textContent: i18n.t("siteusers_deleteInvitationConfirmText", {}), actionName: "DELETE_PENDING_GUEST", params: { siteId: self.siteId, invitationId: invitationId } });
                    });

                $(".sendAgainEmailButton")
                    .off()
                    .on("click", function() {
                        let invitationId = $(this).attr("data-invitationid");
                        console.log("Component(Users)::registerEventsOnPendingInvitationsTable(sendAgainEmailButton) clicked. Invitation Id = " + invitationId);
                        self.reInviteToGuest({ invitationId: invitationId, siteId: self.siteId });
                    });

                // Force width pager size
                setTimeout(() => {
                    $(".kt-datatable__pager-size").css("width", "80px");

                    // Init tooltips
                    $('[data-toggle="kt-tooltip"]')
                        .tooltip({ trigger: "hover" })
                        .on("click", function() {
                            $(this).tooltip("hide");
                        });
                }, 50);
            });
        },

        buildDatatableData() {
            // Prepare data
            let data = [];
            if (this.guestsOfSite && this.guestsOfSite.length > 0) {
                for (let obj of this.guestsOfSite) {
                    let guest = {...obj};
                    // Set the full name
                    guest.fullName = guest.user.firstName + " " + guest.user.lastName;
                    // Set guest email
                    guest.email = guest.user.loginEmail;
                    // Set the guest role
                    if (guest.role && guest.role.isDefault) {
                        guest.roleName = i18n.t("ROLE_" + guest.role.name);
                    } else {
                        guest.roleName = guest.role.name;
                    }
                    // Set the expiration date
                    if (guest.expiredAccessAt) {
                        guest.formatExpiredAt = moment(guest.expiredAccessAt).format(commonVueHelper.getDateStringFormatFromLocale(moment().locale()));
                    } else {
                        guest.formatExpiredAt = i18n.t("common_never");
                    }
                    data.push(guest);
                }
            }
            return data;
        },

        buildPendingGuestsDatatableData() {
            // Prepare data
            let data = [];
            if (this.pendingGuestsOfSite && this.pendingGuestsOfSite.length > 0) {
                data = this.pendingGuestsOfSite.map( guestInvitation => {
                    let customGuestInvitation = { ...guestInvitation };
                    if(guestInvitation.role){
                        if (guestInvitation.role.isDefault) {
                            customGuestInvitation.translatedRoleName = i18n.t("ROLE_" + guestInvitation.role.name)
                        } else {
                            customGuestInvitation.translatedRoleName = guestInvitation.role.name;
                        }
                    }
                    //Format created date
                    if(guestInvitation.createdAt){
                        customGuestInvitation.formatCreatedAt = moment(guestInvitation.createdAt).format(commonVueHelper.getDateStringFormatFromLocale(moment().locale()));
                    }else{
                        customGuestInvitation.formatCreatedAt = "-";
                    }

                    if(guestInvitation.expiredAccessAt){
                        customGuestInvitation.formatExpiredAt = moment(guestInvitation.expiredAccessAt).format(commonVueHelper.getDateStringFormatFromLocale(moment().locale()));
                    }else{
                        customGuestInvitation.formatExpiredAt = "-";
                    }

                    // // Set the expiration date
                    //  if (guestInvitation.expiredAccessAt) {
                    //     customGuestInvitation.formatExpiredAt = moment(guestInvitation.expiredAccessAt).format(commonVueHelper.getDateStringFormatFromLocale(moment().locale()));
                    // } else {
                    //     customGuestInvitation.formatExpiredAt = i18n.t("common_never");
                    // }
                    return customGuestInvitation;
                })
            }
            return data;
        },

        initKtDataTable(data, pageSize) {
            let self = this;
            const options = {
                data: {
                    type: "local",
                    source: commonVueHelper.preventHtmlInjection(data),
                    pageSize: (pageSize ? pageSize : 5),
                    saveState: {
                        webstorage: false
                    }
                },
                layout: commonVueHelper.getLayoutOptions(),
                translate: commonVueHelper.getTranslateOptions(),
                toolbar: commonVueHelper.getToolbarOptions(),
                sortable: true,
                pagination: true,
                columns: [
                    {
                        field: "fullName",
                        title: i18n.t("siteusers_name"),
                        textAlign: "center",
                        template: function(row) {
                            if (row) {
                                const editClass = (self.isSiteUserAdmin ? 'editGuestButton' : '');
                                const editHref = (self.isSiteUserAdmin ? ' href="javascript:;"'  : '');
                                return `
                                    <div class="kt-user-card-v2">
                                        <div class="kt-user-card-v2__details openResourceLink">
                                            <a class="` + editClass + ` kt-user-card-v2__name" data-guestid="` + row.id + `"` + editHref + `>` + row.fullName + `</a>
                                        </div>
                                    </div>
                                `;
                            }
                        }
                    },
                    {
                        field: "email",
                        title: i18n.t("siteusers_email"),
                        textAlign: "center"
                    },
                    {
                        field: "roleName",
                        title: i18n.t("siteusers_roleName"),
                        textAlign: "center",
                        template: function(row) {
                            return '<span class="btn btn-bold btn-sm btn-font-sm btn-label-brand">' + row.roleName + "</span>";
                        }
                    },
                    {
                        field: "expiredAt",
                        title: i18n.t("common_expirationDate"),
                        sortable: "desc",
                        textAlign: "center",
                        template: function(row) {
                            return row.formatExpiredAt;
                        }
                    },
                    {
                        field: "Actions",
                        title: i18n.t("common_actions"),
                        sortable: false,
                        width: 110,
                        overflow: "visible",
                        autoHide: false,
                        textAlign: "center",
                        template: function(row) {
                            if( row.user.id == self.user.id ){
                                return "-"
                            } else if (row.user && self.isSiteUserAdmin) {
                                // Only user with ADMIN role can edit or revoke a guest access
                                return (
                                    `
                                    <button data-toggle="kt-tooltip" data-placement="top" data-original-title="` + i18n.t("common_edit") +
                                    `" type="button" data-guestid="` + row.id +
                                    `" class="btn btn-outline-brand btn-sm btn-icon editGuestButton">
                                        <i class="la la-pencil"></i>
                                    </button>
                                    <button data-toggle="kt-tooltip" data-placement="top" data-original-title="` + i18n.t("common_revoke") +
                                    `" type="button" data-username="` + row.user.firstName + " " + row.user.lastName +
                                    `" data-guestid="` +  row.id +
                                    `" class="btn btn-outline-danger btn-sm btn-icon revokeGuestButton">
                                        <i class="la la-ban"></i>
                                    </button>
                                    `
                                );
                            }else {
                                return "-";
                            }
                        }
                    }
                ]
            };
            // If site table has been already build, destroy the table before creating a fresh instance !
            if (this.siteGuestsTable) {
                $("#guestAccess_guestsDatatable")
                    .KTDatatable()
                    .destroy();
            }

            // According to source list length, show datatable or empty list icon+text
            if (options.data.source && options.data.source.length > 0) {
                // There is data => Hide empty list icon+text
                this.isEmptyList = false;
                // Now, we are ready to build the table
                this.siteGuestsTable = $("#guestAccess_guestsDatatable").KTDatatable(options);
                // Once table is build, register click events on buttons inside the table
                this.registerEventsOnSiteGuestsTable();
            } else {
                // There is no data => Show empty list icon+text
                this.isEmptyList = true;
                this.siteGuestsTable = null;
            }
        },

        initPendingGuestsKtDataTable(data, pageSize) {
            const options = {
                data: {
                    type: "local",
                    source: commonVueHelper.preventHtmlInjection(data),
                    pageSize: (pageSize ? pageSize : 5)
                },
                layout: commonVueHelper.getLayoutOptions(),
                translate: commonVueHelper.getTranslateOptions(),
                toolbar: commonVueHelper.getToolbarOptions(),
                sortable: true,
                pagination: true,
                columns: [
                    {
                        field: "name",
                        title: i18n.t("siteusers_inviteeName"),
                        textAlign: "center",
                        template: function(row) {
                            return '\
                                <div class="kt-user-card-v2">\
                                    <div class="kt-user-card-v2__details centerResource">\
                                        <span class="kt-user-card-v2__name"">' + row.name + "</span>\
                                    </div>\
                                </div>\
                            ";
                        }
                    },
                    {
                        field: "email",
                        title: i18n.t("siteusers_inviteeEmail"),
                        textAlign: "center"
                    },
                    {
                        field: "translatedRoleName",
                        title: i18n.t("siteusers_inviteeAs"),
                        textAlign: "center",
                        template: function(row) {
                            if (row.role){
                                return '<span class="btn btn-bold btn-sm btn-font-sm btn-label-brand">' + row.translatedRoleName + "</span>";
                            } else {
                                return "-";
                            }
                        }
                    },
                    {
                        field: "createdAt",
                        title: i18n.t("common_invitationDate"),
                        sortable: "desc",
                        textAlign: "center",
                        template: function(row) {
                            return row.formatCreatedAt;
                        }
                    },
                    {
                        field: "expiredAt",
                        title: i18n.t("common_expirationDate"),
                        sortable: "desc",
                        textAlign: "center",
                        template: function(row) {
                            return row.formatExpiredAt;
                        }
                    },
                    {
                        field: "Actions",
                        title: i18n.t("common_actions"),
                        sortable: false,
                        width: 110,
                        overflow: "visible",
                        autoHide: false,
                        textAlign: "center",
                        template: function(row) {
                            return (
                                `
                                <button data-invitationid="` +
                                row.id +
                                `" data-toggle="kt-tooltip" data-placement="top" data-original-title="` +
                                i18n.t("common_sendEmailAgain") +
                                `" type="button" class="btn btn-outline-brand btn-sm btn-icon sendAgainEmailButton">
                                    <i class="fa fa-sync"></i>
                                </button>
                                <button data-toggle="kt-tooltip" data-placement="top" data-original-title="` +
                                i18n.t("common_delete") +
                                `" type="button"
                                " data-invitationid="` +
                                row.id +
                                `" class="btn btn-outline-danger btn-sm btn-icon deletePendingInvitationButton">
                                    <i class="la la-trash"></i>
                                </button>
                            `
                            );
                        }
                    }
                ]
            };
            // If site table has been already build, destroy the table before creating a fresh instance !
            if (this.pendingGuestsTable) {
                $("#users_pendingGuestsDatatable").KTDatatable().destroy();
                // After destroy set to null
                this.pendingGuestsTable = null;
            }

            // According to source list length, show datatable or empty list icon+text
            if (options.data.source && options.data.source.length > 0) {
                // There is data => Hide empty list icon+text
                this.isPendingGuestsEmptyList = false;
                // Now, we are ready to build the table
                this.pendingGuestsTable = $("#users_pendingGuestsDatatable").KTDatatable(options);
                // Once table is build, register click events on buttons inside the table
                this.registerEventsOnPendingGuestsTable();
            } else {
                // There is no data => Show empty list icon+text
                this.isPendingGuestsEmptyList = true;
                this.pendingGuestsTable = null;
            }
        },
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
        "app-emptylist": EmptyList,
        "app-sitetitle": SiteTitle
    }
};
</script>

<style scoped>
.baseTemplate {
    display: contents;
}
</style>
